@import 'src/styles/import';

.iconButtonNotificationClass {
  border-radius: 50% !important;
  padding: 2px !important;
  color: white;

  @include breakpoint(desktop) {
    color: var(--bs-link-color);
  }
  @media screen and (min-width: 600px) {
    color: #007aff !important;
  }
}

.notificationContainer {
  top: 60px !important;

  .notificationContent {
    margin: 0;
    font-size: var(--md-font);
    word-break: break-word;
    text-align: left;
  }
}

.notificationContainer::before {
  content: url('../../../assets/svg/arrow_drop_up_white_24dp.svg');
  width: 16px;
  height: 16px;
  z-index: 999999;
  position: absolute;
  top: -16px;
  left: 24px;
}

.notificationContainer::-webkit-scrollbar {
  display: none;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconMaterialDesign {
  margin-right: 16px;
}
