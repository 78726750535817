.container {
  height: auto;
}

.columnsSocialButtons {
  display: flex;
  align-items: center;
  flex-direction: column;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  a {
    color: black !important;
    font-weight: 500;
  }

  @media only screen and (max-width: 767px) {
    margin-bottom: 20px;
  }
}

.rowSocialButtons {
  width: 90%;
  margin: auto;
}

.profilePictureContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  align-items: center;
  margin-top: 20px;

  .socialIconsAlign {
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
    margin-top: 4px;

    img {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  label {
    display: flex;
    justify-content: center;
  }

  .giftCount {
    position: absolute;
    color: white;
    bottom: 25px;
    left: 12%;
  }

  .flagDiv {
    margin-top: 8px;
    display: flex;
    justify-content: center;

    .flagInternalDiv {
      overflow-wrap: break-word;
      text-align: center;
      width: 64px;

      img {
        margin: 0 8px;
        height: 40px;
      }

      .flagName {
        font-size: var(--sm-font);
        margin: 0;
      }
    }
  }

  p {
    text-align: center;
    margin-left: 0 !important;
    font-weight: 500;
  }

  .profilePicDiv {
    display: flex;
    align-items: center;
    width: 150px;
    height: 200px;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    .internalDiv {
      border: 1px solid black;
      border-radius: 50%;
      height: 80px;
      width: 80px;
      margin-top: -50px;
      position: relative;
      object-fit: cover;

      .addPlusIcon {
        position: absolute;
        top: -10px;
        right: -10px;
      }
    }
  }

  .memberSince {
    font-size: var(--sm-font);
    margin: 0;
  }

  .memberSinceMd {
    font-size: var(--md-font);
    margin: 0;
  }

  .channelName {
    font-weight: bold;
    margin-bottom: 8px;
  }
}

.profilePicDiv1 {
  display: flex;
  align-items: center;
  width: 100px;
  height: 150px;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  .internalDiv1 {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    margin-top: -32px;
    position: relative;
    object-fit: cover;

    .addPlusIcon {
      position: absolute;
      top: -10px;
      right: -10px;
    }
  }
}

.connectedPlatform {
  position: relative;

  .platform {
    height: 24px;
  }

  .check {
    position: absolute;
    top: 0;
    right: -2px;
    height: 10px;
    background-color: var(--theme-light-color);
    border-radius: 50%;
    padding: 2px;
  }
}

.menuIcon {
  height: 1.5rem;
  width: 1.5rem;
}

.popup {
  min-width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 1rem;

  .popupCaptureContainer {
    height: 224px;

    video {
      border-radius: 50%;
    }
  }
}

.popupCapturePreview {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.badges {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin: 0 0.25rem;
  }
}

.badgesQuickStarter {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  width: 100%;
  margin-top: 30px;
  padding: 30px 0px;

  img {
    margin: 0 0.25rem;
  }
}

.referral_badges {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 5px;

  span {
    font-size: 14px;
  }

  img {
    margin: 0 0.25rem;
    height: 45px;
  }
}

.referralSection {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.referral_text_section {
  width: 60%;
  // margin-top: 10px;

  @media only screen and (max-width: 767px) {
    padding-left: 10px;
  }

  p {
    text-align: left;
    word-break: break-all;
    font-size: 12px;
    margin-bottom: 2px;
  }
}

.referral_text_sub,
.referral_text_sub_bottom {
  text-align: left !important;
  margin-top: 10px;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
  color: #000000;

  @media only screen and (max-width: 330px) {
    font-size: 12px;
    line-height: 15px;
  }
}
.referral_text_sub_bottom {
  margin-top: 0;
  margin-bottom: 0;
}
.bottomWrapper .referral_text_sub,
.bottomWrapper .referral_text_sub_bottom {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  margin: 0;

  @media only screen and (max-width: 330px) {
    font-size: 12px;
    line-height: 15px;
  }
}

.referral_description {
  font-size: 12px;
  color: #000000;
  text-align: start;
  margin: 20px 15px
}

.referral_container {
  display: flex;
  overflow: hidden;

  p {
    text-align: justify;
    font-size: 13px;
    letter-spacing: -0.5px;
    text-align: left;
    margin: 0;
  }

  .float {
    float: left;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    shape-outside: inset(calc(100% - 80px) 0 0);

    .sub {
      position: relative;
      transform: translate(-8px, -5%);
      img {
        margin: 10px 0px 0 10px;
        width: 135px;
        height: auto;
      }
      .giftCardChannelName {
        position: absolute;
        top: 24%;
        left: 12%;
        color: white;
        width: 100px;

        @media only screen and (max-width: 350px) {
          left: 12%;
        }

        .label {
          font-size: 7px;
          word-wrap: break-word;
        }
      }
      
      .giftCount {
        font-size: 8px;
        word-wrap: break-word;
      }      
    }
  }
}

.giftCardCount {
  position: absolute;
  bottom: 5%;
  left: 9%;
  transform: translate(-50%, -50%);
  color: white;

  @media only screen and (max-width: 350px) {
    left: 8%;
  }

  .count {
    font-weight: bold;
    font-size: 12px;
    line-height: 1rem;
    text-align: left;
    margin-bottom: 0px;

    @media only screen and (max-width: 767px) {
      font-size: 10px;
    }

    @media only screen and (max-width: 350px) {
      font-size: 8px;
    }
  }

  .label {
    font-size: 8px;
    font-weight: 600;

    @media only screen and (max-width: 350px) {
      font-size: 6px;
    }
  }

  .giftCount {
    font-size: 8px;
    font-weight: 600;

    @media only screen and (max-width: 350px) {
      font-size: 6px;
    }
  }
}

.referral_divider {
  width: 100%;
  height: 2px;
  margin-top: 10px;
  background-color: black;
}

.referral_text_without_contact {
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  padding-top: 5px;
  margin-top: 5px;
  border-top: #000000 1.5px solid;
}